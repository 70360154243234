import React from 'react'
import '../styles/styles.scss'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'
import classNames from 'classnames'
import Helmet from 'react-helmet'
import Modal from 'react-modal'
import { windowScrolledToFooter } from '../lib/helpers'
import { isMobile, isMobileOnly, isChrome, isFirefox, isSafari } from 'react-device-detect'
import Tick from '../images/icons/tick.svg'
import ImprintOverlay from './imprint-overlay'
import NewsletterSignupOverlay from './newsletter-signup-overlay'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

class Content extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      path: null,
      prevPath: null,
      bgChangeOffset: false,
      scrolled: false,
      isCookieBarFaded: false,
      isPageTransitioning: false,
      transitionStatus: null,
      isFadeInit: false,
      isNewsletterOverlayOpen: false,
      isImprintOverlayOpen: false,
      isNavHoverPrevented: false,
      isFooterVisible: false,
      isTransitionOverlayHidden: false,
    }
    this.toggleNewsletterOverlay = this.toggleNewsletterOverlay.bind(this)
    this.toggleImprintOverlay = this.toggleImprintOverlay.bind(this)
  }

  updateHeight = () => {
    const innerH = window.innerHeight
    if (innerH) {
      document.getElementById('content-wrapper').style.minHeight = `${innerH}px`
    }
  }

  toggleNewsletterOverlay = () => {
    this.setState({
      isNewsletterOverlayOpen: !this.state.isNewsletterOverlayOpen,
    })
  }

  toggleImprintOverlay = () => {
    this.setState({
      isImprintOverlayOpen: !this.state.isImprintOverlayOpen,
    })
  }

  activateNewsletterOverlayLinks = () => {
    document.querySelectorAll('.editor-newsletter-link').forEach(link => {
      link.addEventListener('click', e => {
        e.preventDefault()
        this.setState({
          isNewsletterOverlayOpen: true,
        })
      })
    })
  }

  activateImprintOverlayLinks = () => {
    document.querySelectorAll('.editor-imprint-link').forEach(link => {
      link.addEventListener('click', e => {
        e.preventDefault()
        this.setState({
          isImprintOverlayOpen: true,
        })
      })
    })
  }

  toggleFadeElsOffset = () => {
    !this.state.fadeElsOffset && this.setState({ fadeElsOffset: true })
    setTimeout(() => {
      this.setState({
        fadeElsOffset: false,
      })
    }, 1600)
  }

  onPageChange = () => {
    // get new path
    const pathname = location && location.pathname
    // get last path
    const prevPath = window.previousPath

    if (pathname !== null) {
      if (this.state.path !== pathname) {
        this.setState({
          // set new path
          path: pathname,
          // set last path
          prevPath: prevPath,
        })
        this.toggleFadeElsOffset()
      }
    }
    this.activateNewsletterOverlayLinks()
    this.activateImprintOverlayLinks()
  }

  setPageTransitionTimeout = () => {
    this.setState({
      isPageTransitioning: true,
    })
    setTimeout(() => {
      this.setState({
        isPageTransitioning: false,
      })
    }, 800)
  }

  onScroll = () => {
    const isFooterVisible = windowScrolledToFooter()
    this.setState({
      isFooterVisible: isFooterVisible,
    })
    if (this.state.isFooterVisible) {
      document.body.classList.add('is-footer-visible')
    } else {
      document.body.classList.remove('is-footer-visible')
    }
    if (this.state.scrolled && window.scrollY <= 10) {
      this.setState({ scrolled: false })
    } else if (!this.state.scrolled && window.scrollY > 10) {
      this.setState({ scrolled: true })
    }
  }

  onAcceptAllCookies = () => {
    initializeAndTrack(location)
    this.setState({ isCookieBarFaded: true })
  }

  render() {
    const { children, section, singleView, imprintContent, bgBlack } = this.props
    const htmlClasses = classNames({
      'is-bg-black': bgBlack,
      'bg-change-offset': this.state.bgChangeOffset,
      'is-mobile': isMobile,
      'is-mobile-only': isMobileOnly,
      'is-firefox': isFirefox,
      'is-safari': isSafari,
      'is-chrome': isChrome,
    })
    const classes = classNames({
      'is-bg-black': bgBlack,
      'is-fade-init': this.state.isFadeInit,
      'is-mob-footer-hidden': this.props.mobFooterHidden,
    })

    return (
      <>
        <Helmet>
          <html
            className={`${htmlClasses} ${section ? 'is-section-' + section : ''} ${
              singleView ? 'is-single-view-' + section : ''
            }`}
          ></html>
        </Helmet>
        <div className={`content-wrapper ${classes}`} id="content-wrapper">
          <div className="main-content">
            <div>{children}</div>
          </div>
          <CookieConsent
            location="top"
            buttonText={
              <>
                <div>
                  Only necessary <br />
                </div>
                <div>
                  <Tick />
                </div>
              </>
            }
            declineButtonText={
              <>
                <div>Allow all</div>
                <div>
                  <Tick />
                </div>
              </>
            }
            enableDeclineButton
            cookieName="gatsby-gdpr-google-analytics-balkan-projects"
            containerClasses={`cookie-consent ${this.state.isCookieBarFaded ? 'is-faded' : ''}`}
            style={{
              background: 'rgba(229, 229, 229, 0.7)',
              color: 'black',
              left: 'auto',
              right: '0px',
              width: '359px',
              alignItems: 'center',
              zIndex: 999999,
            }}
            contentStyle={{ margin: '0px', flex: '1 0 0' }}
            buttonClasses={'cookie-consent__accept'}
            declineButtonClasses={'cookie-consent__decline'}
            expires={150}
            disableButtonStyles={true}
            onAccept={() => {
              this.onAcceptAllCookies()
            }}
          >
            <div className="cookie-consent__info">
              <div>
                We use cookies to analyse our <br /> website and optimize your <br /> experience.
                For more information, see our <Link to="/imprint">privacy policy</Link>
              </div>
            </div>
          </CookieConsent>
          <Modal
            className="overlay-container"
            isOpen={this.state.isNewsletterOverlayOpen}
            closeTimeoutMS={400}
          >
            <NewsletterSignupOverlay toggleNewsletterOverlay={this.toggleNewsletterOverlay} />
          </Modal>
          <Modal
            className="overlay-container"
            isOpen={this.state.isImprintOverlayOpen}
            closeTimeoutMS={400}
          >
            <ImprintOverlay
              toggleImprintOverlay={this.toggleImprintOverlay}
              imprintContent={imprintContent}
            />
          </Modal>
        </div>
      </>
    )
  }
  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }
    window.addEventListener('scroll', this.onScroll)
    Modal.setAppElement('body')
    this.onScroll()
    this.onPageChange()
    this.updateHeight()
  }
  componentDidUpdate() {
    this.onPageChange()
    this.updateHeight()
    if (windowScrolledToFooter()) {
      document.body.classList.add('is-footer-visible')
    }
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }
}

export default Content
