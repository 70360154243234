import { graphql, StaticQuery } from 'gatsby'
import React, { useState } from 'react'
import { useLocation } from '@reach/router'
import Content from '../components/content'

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
    imprint: sanityPage(_id: { regex: "/(drafts.|)959ebcde-ce59-46ef-9d0a-1fae58b45617/" }) {
      title
      ...PageInfo
    }
  }
`

function ContentContainer(props) {
  const location = useLocation()
  const { navMenuTitle } = props
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          )
        }

        return (
          <Content
            {...props}
            location={location}
            siteTitle={data.site.title}
            imprintContent={data.imprint._rawContent}
            navMenuTitle={navMenuTitle}
          />
        )
      }}
    />
  )
}

export default ContentContainer
